import React from 'react';
import '../styles/meetTheTeam.css'; // External CSS for styling
import placeholder from '../images/User_Avatar_2.png'
import image1 from '../images/hackathon_164 Large.jpeg'
import image2 from '../images/hackathon_165 Large.jpeg'
import image3 from '../images/mainos2 Large.png'

const About = () => {
  const teamMembers = [
    { name: 'Annika Eloranta', image: placeholder, description: 'Computer Science & Automation Software at Aalto University, web developer' },
    { name: 'Kristian Kholodov', image: placeholder, description: 'Automation and Robotics Engineering student at Aalto University, specialising in embedded systems development' },
    { name: 'Aarno Koponen', image: placeholder, description: 'Electronics and Electrical engineering student at Aalto University, R&D' },
    { name: 'Miikka Leppinen', image: placeholder, description: 'Automation and robotics student at Aalto University.' },
    { name: 'Emil Lintunen', image: placeholder, description: 'Maker, hacker, student of Aalto, teacher and R&D engineer' },
    { name: 'Rika Lintunen', image: placeholder, description: 'Automation & robotics student at Aalto, electronics designer, embedded programmer and maker.' },
    { name: 'Pinja Mäkeläinen', image: placeholder, description: 'Electronic and Digital Systems student at Aalto University' },
  ];

  return (
    <div class="homeContent">
      <div className='ourStory'>
        <div className='about-container'>
          <h2>Aestus Systems' story</h2>
        </div>
        <div className='textAndPicture'>
          <p style={{paddingTop:'8vw'}}>Aestus Systems’ story starts at the Define Hackathon 
            on 29th of September 2024. Our team of students attended the hackathon with no 
            expectation of winning. During the hackathon we worked hard to create a 
            prototype of a meaningful product that can solve problems regarding 
            real-time data gathering and usage. We believe that reliable and accurate 
            data should be the base of critical decision making, and such data should 
            be available in an effortless and inexpensive manner. These values create 
            the whole base for our journey. </p>
            <div className='image'>
              <img src={image1} alt="image1" />
            </div>
        </div>
        <div className='pictureAndText'>
          <div className='image'>
              <img src={image2} alt="image2" />
            </div>
          <p style={{paddingTop:'10vw'}}>Much to our surprise, we won the Define 
            hackathon with our Kivi Node prototype. Ever since, we have been 
            developing our technologies and working hard to bring our vision to 
            life. Now we are happy to announce that we are attending the Define 
            accelerator during the spring of 2025 to escalate our evolution and gain more 
            understanding about the industry. </p>
        </div>
        <div className='textAndPicture'>
          <p style={{paddingTop:'10vw'}}>Currently we are developing the first 
            commercial version of Kivi Nodes. Please contact us 
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfEoRwmen0gs06-t59qFrzh_7Qhpnl-XIMXJFhgw8E55kie-Q/viewform"> here </a> 
             if you are interested in our pilot project or if you would like to 
            collaborate with us. </p>
            <div className='image'>
              <img src={image3} alt="image3" />
            </div>
        </div>
      
      </div>
      <div className='about-container'>
        <h2>Meet the team</h2>
      </div>
      <div className="about-container">
        {teamMembers.map((member, index) => (
          <div key={index} className="team-member">
            <img src={member.image} alt={member.name} className="team-image" />
            <div className="member-info">
              <h5>{member.name}</h5>
              <h4>{member.description}</h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default About;