import React, { useState } from 'react';
import { CFooter, CLink } from '@coreui/react'


function Footer() {
    return (
        <CFooter>
  <div>
    <span>&copy; 2024 Aestus Systems</span>
  </div>

</CFooter>
    );
}

export default Footer