import Spline from '@splinetool/react-spline';

export default function App() {
  return (
    <div class="homeContent">
        <div class="splineComponent">
            <Spline scene="https://prod.spline.design/B3rVrUr-NjxlqChC/scene.splinecode" />
        </div>
    </div>
  );
}
