import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { CNavbar, CNavbarNav, CNavbarBrand, CContainer, CNavbarToggler, CCollapse, CNavLink } from '@coreui/react'
import '@coreui/coreui/dist/css/coreui.min.css'


function Navbar() {
  const [visible, setVisible] = useState(false)
  return (
    <>
      <CNavbar expand="lg" className="bg-body-tertiary"  >
        <CContainer fluid>
          <CNavbarBrand href="/">Aestus Systems</CNavbarBrand>
          <CNavbarToggler
            aria-label="Toggle navigation"
            aria-expanded={visible}
            onClick={() => setVisible(!visible)}
          />
          <CCollapse className="navbar-collapse" visible={visible}>
            <CNavbarNav as="nav">
              <CNavLink href="/" active>
                Home
              </CNavLink>
              <CNavLink href="/products">Products</CNavLink>
              <CNavLink href="/about">Our Story</CNavLink>
            </CNavbarNav>
          </CCollapse>
        </CContainer>
      </CNavbar>
    </>
  );
}

export default Navbar;