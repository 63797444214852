import React from 'react';
import BackgroundTrinket from '../components/BackgroundTrinket';
import KiviNode from '../images/products/KiviNode.png'

function Home() {
  return (
<div class="homeContent">
    <div class="slogan-section">
      <div class="slogan-img">
        <BackgroundTrinket />
      </div>
      <div class="vignette"> </div>
      <div class="slogan-text">
        <h1>Here at</h1>
        <h2>Aestus Systems</h2>
        <h1>we believe</h1> 
        <h1>in a safer</h1>
        <h1>future.</h1>
      </div>
    </div>

    <div className='productBanner'>
      <div className='productBannerText'>
        <h3>Explore our product,</h3>
        <h3>Kivi Node, <a href="/products" rel="/products"> here </a> !</h3> 
      </div>
      <div className='productBannerPic'>
        <img src={KiviNode} alt="Picture of Kivi Node" />
      </div>
    </div>

    <div className='storyBanner'>
      <div className='storyBannerText'>
        <h3>Learn about <a href="/about" rel="/about"> our story</a> and team. </h3>
      </div>
    </div>

    <div className='demoBanner'>
      <div className='demoBannerText'>
        <h3>Interested in collaborating?</h3>
        <h3>Book a <a href="https://docs.google.com/forms/d/e/1FAIpQLSfEoRwmen0gs06-t59qFrzh_7Qhpnl-XIMXJFhgw8E55kie-Q/viewform"> demo</a> with us!</h3>
      </div>
    </div>

</div>
  );
}

export default Home;